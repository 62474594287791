<template>
	<div>
		<div class="p-4">
			<section aria-labelledby="notes-title">
				<div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
					<div class="divide-y divide-gray-200">
						<div class="px-4 pt-4 pb-3 sm:px-6">
							<h2 id="responses-title" class="text-lg font-medium text-gray-900">Activity Forms</h2>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script lang="ts" setup></script>

<style scoped></style>
